/*
 * @Description: 创建服务单混入
 * @Author: ChenXueLin
 * @Date: 2021-12-15 10:49:29
 * @LastEditTime: 2022-07-11 10:07:57
 * @LastEditors: ChenXueLin
 */
import { getFrameworkTree } from "@/api";
import { telAndMobileValid } from "@/utils/validate";
import goBack from "@/mixins/goBack";
export default {
  data() {
    return {
      loading: false,
      baseInfoForm: {
        corpId: "",
        linkmanrpid: "",
        solueIsDept: 1, //指派类型1部门0员工
        departmentId: [], //指派部门
        solveRpid: "", //指派处理人，
        insAddress: "", //详细地址
        contactPhone: "", //电话
        expecttime: "",
        remark: "",
        defaultCity: "" //默认选中地址
      }, //基本信息
      addContactVisible: false, //添加联系人弹框
      treeData: []
    };
  },
  components: {},
  mixins: [goBack],
  computed: {
    rules() {
      let baseInfoFormRules = {};
      if (this.baseInfoForm.solueIsDept == 1) {
        baseInfoFormRules = {
          corpId: [
            {
              required: true,
              message: "请选择客户名称",
              trigger: ["blur", "change"]
            }
          ],
          linkmanrpid: [
            {
              required: true,
              message: "请选择联系人名称",
              trigger: ["blur", "change"]
            }
          ],
          expecttime: [
            {
              required: true,
              message: "请选择解决时间",
              trigger: ["blur", "change"]
            }
          ],
          contactPhone: telAndMobileValid.required({
            requiredMsg: "请输入手机号码",
            message: "号码格式不正确",
            trigger: ["blur", "change"]
          }),
          // defaultCity: [
          //   {
          //     required: true,
          //     message: "请选择地址",
          //     trigger: ["blur", "change"]
          //   }
          // ],
          // insAddress: [
          //   {
          //     required: true,
          //     message: "请输入详细地址",
          //     trigger: ["blur", "change"]
          //   }
          // ],
          solueIsDept: [
            {
              required: true,
              message: "请选择指派类型",
              trigger: ["blur", "change"]
            }
          ],
          departmentId: [
            {
              required: true,
              message: "请选择指派部门",
              trigger: ["blur", "change"]
            }
          ]
        };
      } else {
        baseInfoFormRules = {
          corpId: [
            {
              required: true,
              message: "请选择客户名称",
              trigger: ["blur", "change"]
            }
          ],
          linkmanrpid: [
            {
              required: true,
              message: "请选择联系人名称",
              trigger: ["blur", "change"]
            }
          ],
          expecttime: [
            {
              required: true,
              message: "请选择解决时间",
              trigger: ["blur", "change"]
            }
          ],
          contactPhone: telAndMobileValid.required({
            requiredMsg: "请输入手机号码",
            message: "号码格式不正确",
            trigger: ["blur", "change"]
          }),
          // defaultCity: [
          //   {
          //     required: true,
          //     message: "请选择地址",
          //     trigger: ["blur", "change"]
          //   }
          // ],
          // insAddress: [
          //   {
          //     required: true,
          //     message: "请输入详细地址",
          //     trigger: ["blur", "change"]
          //   }
          // ],
          solueIsDept: [
            {
              required: true,
              message: "请选择指派类型",
              trigger: ["blur", "change"]
            }
          ],
          solveRpid: [
            {
              required: true,
              message: "请选择指派人",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      return baseInfoFormRules;
    }
  },
  watch: {
    // 监听指派类型
    "baseInfoForm.solueIsDept": {
      immediate: true,
      handler(val) {
        if (val == 1) {
          //部门
          this.baseInfoForm.solveRpid = "";
        }
        if (val == 0) {
          //员工
          this.baseInfoForm.departmentId = [];
        }
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    //初始化数据
    async initData() {
      let promiseList = [
        getFrameworkTree() //获取部门结构
      ];
      let [frameworkRes] = await Promise.all(promiseList);
      this.handleTreeData(frameworkRes.data.children);
    },
    //处理客户名称下拉框
    handleChange() {
      this.baseInfoForm.corpName = this.corpList.find(item => {
        return item.corpId == this.baseInfoForm.corpId;
      }).corpName;
    },
    //全部员下拉框处理逻辑
    handleLoadEmployee: _.debounce(async function(val) {
      if (val) {
        this.loadEmployeeData(val);
      }
    }, 300),
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].personSum) {
          data[i]["label"] = data[i].name + "(" + data[i].personSum + ")";
        } else {
          data[i]["label"] = data[i].name;
        }
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    },
    //添加联系人之后更新下拉框
    updateList() {
      this.getContactList();
    }
  }
};
